import '../sass/preloader.sass';

document.addEventListener("DOMContentLoaded", () => {
    // Прелоадер
    let preloader = document.querySelector('#preloader');

    // Если прелоадера нет, то выходим из функции
    if (!preloader) {
        return;
    }

    // Картинка-анимация в прелоадере
    let preloaderStatus = document.querySelector('#preloader-status');

    // Страница загрузилась - скрываем прелоадер (сделано через transition)
    preloader.classList.add('hide');

    // Ждем немного (сначала исчезнет картинка прелоадера)
    setTimeout(() => {
        // Открываем для body скроллбар
        document.body.classList.remove('have-preloader');

        // Еще ждем немного и убираем z-index у прелоадера
        setTimeout(() => {
            preloader.classList.add('disable-zindex');
            preloader.remove();
        }, 350);
    }, 350);

    initIframePreloaders()
});

function initIframePreloaders(){
    const iframeContainers = document.querySelectorAll('div:has(> iframe)'); // div has direct iframe inside
    const preloader = document.getElementById('preloader'); // get our basic preloader
    
    if(iframeContainers.length && preloader){
        iframeContainers.forEach(div => {
            const iFrame = div.querySelector('iframe');
            const newPreloader = preloader.cloneNode(true);
            div.style.position ='relative';
            newPreloader.style.position = 'absolute';
            div.append(newPreloader);
            iFrame.addEventListener('load', () => {
                newPreloader.classList.add('hide');
                setTimeout(() => {
                    newPreloader.remove();
                }, 350);
            })
        })
    }
}
